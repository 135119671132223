import { authenticationService } from '../_services/authentication.service';

export function authHeader() {
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.access) {
       return new Headers({ Authorization: `Bearer ${currentUser.access}`})

    } else {
        return new Headers();
    }
}


export function authHeaderPost() {
    const currentUser = authenticationService.currentUserValue;
     if (currentUser && currentUser.access) {
       return new Headers({ 
        Authorization: `Bearer ${currentUser.access}`,
       'Content-Type': 'application/json'})
    //     return { 
    //         Authorization: `Bearer ${currentUser.token}`,
    //  };
    } else {
        return new Headers();
    }

}