import { BehaviorSubject } from 'rxjs';

import config from '../config';
import { handleResponse } from '../_helpers/handle-response';

const currentUserSubject = new BehaviorSubject(localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')??"") : null);

async function login(username:any, password:any) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ username : username, password : password})
    };
    return await fetch(`${config.apiUrl}/api/v1/token/pair`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);
            return user;
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};