import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { eventService } from "../../_services/event.service";
import { toast } from 'react-toastify';
import { error } from "console";
import Loader from "../Common/Loader";

const RegisterNewUser = () => { 

    const navigate = useNavigate();
    const [username, setUsername] = useState<any>('');
    const [email, setEmail] = useState<any>('');
    const [password, setPassword] = useState<any>('');
    const [confirmPassword, setConfirmPassword] = useState<any>('');
    const [usernameError, setUsernameError] = useState<any>('');
    const [emailError, setEmailError] = useState<any>('');
    const [passwordError, setPasswordError] = useState<any>('');
    const [confirmPasswordError, setConfirmPasswordError] = useState<any>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onCreateClick = () => {
        setUsernameError('');
        setEmailError('');
        setPasswordError('');
        setConfirmPasswordError('');

      if(validate())
       {
            setIsLoading(true);
            eventService.registerUser(username, email, password)
            .then((response :any) =>{
                setIsLoading(false);
                if(response.message == "User created."){
                    toast.success(response.message);
                    onBackToLoginClick();
                }
                else{
                    toast.error(response.message);
                }
            }).catch((error : any) => { 
                setIsLoading(false);
                toast.error(error) 
            });
        }
    }

    const validate = () => {
        let isValid = true;
        if (!username) {
            isValid = false;
            setUsernameError("Please enter your username.");
          }
          if (typeof username !== "undefined" && username.length > 0) {
            const re = /^\S*$/;
            if (username.length < 6 || !re.test(username)) {
              isValid = false;
              setUsernameError("Please enter valid username.");
            }
          }

          if (!email) {
            isValid = false;
            setEmailError("Please enter your email.");
          }

          if (typeof email !== "undefined" && email.length > 0) {
            const re = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
            if (!re.test(email)) {
              isValid = false;
              setEmailError("Please enter valid email.");
            }
          }

          if (!password) {
            isValid = false;
            setPasswordError("Please enter your password.");
          }

          if (!confirmPassword) {
            isValid = false;
            setConfirmPasswordError("Please enter your confirm password.");
          }

          if (typeof password !== "undefined" && password.length > 0) {
            if (password.length < 8) {
              isValid = false;
              setPasswordError("Please add at least 8 character.");
            }
          }

          if (
            typeof password !== "undefined" &&
            typeof confirmPassword !== "undefined" && password.length > 0 && confirmPassword.length > 0
          ) {
            if (password != confirmPassword) {
              isValid = false;
              setPasswordError("Confirm passowrd and password don't match.");
            }
          }
      
          return isValid;
    }

    const onBackToLoginClick = () => {
         navigate('/Login');
    }

    return(
        <div className="login-form container-custom">
            <h4>Register New User</h4>
            <form>
                {isLoading && <div className="center"><Loader/></div> }
                <div className="mb-3">
                    <label className="form-label">Username</label>
                    <input type="text" value={username} className="form-control" id="username" placeholder="Username" onChange={(e:any) =>  setUsername(e.target.value)}  required/>
                    {usernameError && <label className="errorLabel">{usernameError}</label>}
                </div>
                <div className="mb-3">
                    <label className="form-label">Email</label>
                    <input type="text" value={email} className="form-control" id="email" placeholder="Email" onChange={(e:any) =>  setEmail(e.target.value)}  required/>
                    {emailError && <label className="errorLabel">{emailError}</label>}
                </div>
                <div className="mb-3">
                    <label className="form-label">Password</label>
                    <input type="password" value={password} className="form-control" id="password" placeholder="Password" onChange={(e:any) =>  setPassword(e.target.value)} required/>
                    {passwordError && <label className="errorLabel">{passwordError}</label>}
                </div>
                <div className="mb-3">
                    <label className="form-label">Confirm Password</label>
                    <input type="password" value={confirmPassword} className="form-control" id="confirmpassword" placeholder="Confirm Password" onChange={(e:any) =>  setConfirmPassword(e.target.value)} required/>
                    {confirmPasswordError && <label className="errorLabel">{confirmPasswordError}</label>}
                </div>
                <div className="d-flex">
                    <button type="button" className="btn btn-primary me-2" onClick={onCreateClick}>Create</button>
                    <button type="button" className="btn btn-primary me-2" onClick={onBackToLoginClick}>Back to Login</button>
                </div>
            </form>
        </div>
) }

export default RegisterNewUser;