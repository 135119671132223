import { toast } from 'react-toastify';
import { authenticationService } from '../_services/authentication.service';

export function handleResponse(response : any) {
    if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
                //location.reload(true);
                window.location.reload();
            }
            const error = response.statusText || "Some error occurs during processing. Please try again.";
            return Promise.reject(error);
    }

    return response.json().then((result:any) => {
    try{
        return result;
    }
    catch(error :any) 
    {
        toast.error(error);
    }
    });
}